import { Col } from "react-bootstrap";
import dFitLogo from "../../assets/icons/dFitLogo.svg"
import leftbgCenter from "../../assets/images/leftbgcenter.svg";

const LeftColoumn = () => {
  return (
    <>
      <div className="position-fixed z-3 social-logo mb-2">
        <img className="py-2 ml-2" src={dFitLogo} width={108} alt="dsocial logo" />
      </div>
      <Col xl={5} className="left-col position-relative">
        <div className="position-absolute ministry-wrapper translate-middle">
          <img src={leftbgCenter} width={500} alt="l1fe ministy" className="center-logo " />
        </div>
      </Col>
    </>
  )
}

export default LeftColoumn