import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useForm, useFormState } from "react-hook-form";
import eyeIcon from "./../../../assets/icons/eyeIcon.png";
import PasswordInput from "../../shared/PasswordInput";
import { useLocation } from "react-router-dom";
import instance from "../../../utils/axios";
import { API } from "../../../utils/apiendpoints";
import { registerUser } from "../../../store/registeruser";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const Step2 = ({ setStep, children }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();

  const [countries, setCountries] = useState([]);

  const { loader } = useSelector((store) => store.registerReducer);

  const {
    register,
    handleSubmit,
    trigger,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange", reValidateMode: "onChange", criteriaMode: "all", defaultValues: {
      country: "",
      password: "",
      confirmPassword: "",
    }
  });

  const { dirtyFields } = useFormState({
    control
  });

  const onFormSubmit = (data) => {
    trigger()
      .then((res) => {
        if (res) {
          const email = queryParams.get("email");
          data.email = email;
          delete data.confirmPassword;

          dispatch(registerUser(data))
            .then((res) => {
              if (!res?.error) {
                setStep((prev) => prev + 1);
              }
            })
            .catch((error) => { });
        }
      })
      .catch((err) => console.log(err));
  };

  const getCountries = async () => {
    await instance.get(API.getCountries).then((res) => {
      if (!res?.error) {
        setCountries(res?.data);
      }
    });
  };

  useEffect(() => {
    getCountries();
  }, []);

  const hasSpecialChar = /[~`!@#$%^&*()_\-\\+=:;"'?\\/>.<,{}\\[\]]/;
  const hasNumber = /^(?=.*\d)[^\s]/;
  const hasCapitalLetter = /^(?=.*[A-Z])[^\s]/;

  return (
    <>
      <form onSubmit={handleSubmit(onFormSubmit)} className="form-fields mt-32">
        <div className="common-input styled-select mt-32 position-relative">
          <select
            aria-label="Default select example"
            {...register("country", {
              required: true,
              validate: (value) => {
                return (
                  value !== "Select Country" ||
                  // "This is A Required Field"
                  "Please select a country"
                );
              },
            })}
            className={`form-select ${errors?.country ? "error-border" : "normal-border"
              }`}
          >
            <option defaultValue hidden>
              Select Country
            </option>
            {countries?.map((ele) => {
              return (
                <option key={ele} value={ele}>
                  {ele}
                </option>
              );
            })}
          </select>
          {errors?.country && (
            <p className={`error-text`}>Please select a country</p>
          )}
        </div>
        <div className="w-100 mt-32">
          <PasswordInput
            name="password"
            placeholder="Enter Your Password"
            icon={eyeIcon}
            errors={errors}
            register={register("password", {
              minLength: {
                value: 8,
                message: "Password should contain minimum 8 characters ",
              },
              validate: {
                hasSpecialChar: (passwordInput) => hasSpecialChar.test(passwordInput),
                hasNumber: (passwordInput) => hasNumber.test(passwordInput),
                hasCapitalLetter: (passwordInput) => hasCapitalLetter.test(passwordInput),
              },
            })}
          />
        </div>
        {dirtyFields.password &&
          <div>
            <div className="text-start fw-normal fs-6 mb-1">For your security we require that passwords:</div>
            <div className="d-flex flex-direction-row align-self-center mt-2">
              <div className={`${errors.password?.types?.minLength ? `check-circle  check-not-passed-circle` : `check-circle check-passed-circle`}`} />
              <div className={`${errors.password?.types?.minLength ? `check-failed-text` : `check-passed-text`} align-self-center ms-2`}>Be atleast 8 characters</div>
            </div>
            <div className="d-flex flex-direction-row align-self-center mt-1">
              <div className={`${errors.password?.types?.hasSpecialChar ? `check-circle  check-not-passed-circle` : `check-circle check-passed-circle`}`} />
              <div className={`${errors.password?.types?.hasSpecialChar ? `check-failed-text` : `check-passed-text`} align-self-center ms-2`}>Contain atleast 1 special character</div>
            </div>

            <div className="d-flex flex-direction-row align-self-center mt-2">
              <div className={`${errors.password?.types?.hasNumber ? `check-circle  check-not-passed-circle` : `check-circle check-passed-circle`}`} />
              <div className={`${errors.password?.types?.hasNumber ? `check-failed-text` : `check-passed-text`} align-self-center ms-2`}>Contain atleast 1 number</div>
            </div>

            <div className="d-flex flex-direction-row align-self-center mt-2">
              <div className={`${errors.password?.types?.hasCapitalLetter ? `check-circle  check-not-passed-circle` : `check-circle check-passed-circle`}`} />
              <div className={`${errors.password?.types?.hasCapitalLetter ? `check-failed-text` : `check-passed-text`} align-self-center ms-2`}>Contain atleast 1 capital letter</div>
            </div>
          </div>
        }
        <div className="w-100 mt-32">
          <PasswordInput
            name="confirmPassword"
            placeholder="Confirm password"
            icon={eyeIcon}
            errors={errors}
            register={register("confirmPassword", {
              validate: {
                doesPasswordsMatch: (value) => value === watch("password"),
              }
            })}
          />
        </div>
        {dirtyFields.confirmPassword &&
          <div className="d-flex flex-direction-row align-self-center mt-2">
            <div className={`${errors.confirmPassword?.types?.doesPasswordsMatch ? `check-circle check-failed-circle` : `check-circle check-passed-circle`}`} />
            <div className={`align-self-center ms-2 check-text`}>{errors.confirmPassword?.types?.doesPasswordsMatch ? 'Please try again' : 'It\'s a match!'}</div>
          </div>
        }
        <Button
          className="custom-btn mb-24 mt-4"
          variant="dark"
          type="submit"
        >
          {loader ? (
            <>
              <span
                className="spinner-border me-2 spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </>
          ) : (
            "Next"
          )}
        </Button>

        {children}
      </form >
    </>
  );
};

export default Step2;
